import { Directive, ElementRef, Input, OnChanges } from '@angular/core'

@Directive({
  selector: '[sviFocus]'
})
export class FocusDirective implements OnChanges {
  @Input('sviFocus') isFocused: boolean

  constructor(private host: ElementRef) {}

  ngOnChanges(): void {
    if (this.isFocused) {
      this.host.nativeElement.focus()
    }
  }
}
